@use "sass:math"; @use "sass:color"; @import "css-params-generated.scss";
/** @module styles.less
 *  @since 2023.01.27, 13:36
 *  @changed 2023.03.04, 16:02
 */

#__next,
body {
  margin: 0;
  padding: 0;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
}

body {
  // font-family: $defaultFont;
  // font-size: $defaultFontSizePx;
  background-color: $bodyBgColor;
  color: $textColor;
}

a {
  color: $defaultLinkColor;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}

// Fix MUI buttons
button.FixMuiButton {
  // TODO: To fix it with MUI tools?
  > .Text {
    $shift: 2px;
    margin-top: $shift;
    margin-bottom: -$shift;
  }
}
